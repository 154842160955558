<template>
  <div class="topic d-flex" v-if="topic.valid === true">
    <v-sheet
    :color="toggled ? 'primary':'transparent'"
    :class="{'white--text elevation-0 not-outlined': toggled }"
    elevation="2"
    flat
    class="d-flex justify-content-center mr-3 mb-3 no-select elevation-0"
    @click="toggleItem">
    <span class="d-block">{{topic.name}}</span>
  </v-sheet>

  </div>
</template>

<script>

export default {
  name: 'selectTopic',
  props:['topic'],
  components:{

  },
  watch:{
    initVal: function(val){
      process.env.NODE_ENV === "development" ? console.log( 'things changed', val ) : null;
    }
  },
  computed: {

  },
  data: () => ({
    val: null,
    edit:false,
    toggled:false,
    selected: false,
  }),
  methods:{
      onBlur() {
          // console.log(`text blur:`);
      },
      onClose() {
          // console.log(`text close:`);
      },
      onChange () {
          // console.log(`text change:`);
      },
      onOpen () {
          // console.log(`text open:`);
      },
      toggleItem: function() {
        this.toggled = !this.toggled;
        this.selected = !this.toggled;
        this.toggled === true ? this.$emit('selected-topic', this.topic) : null;
      }
  },

};




</script>


<style lang="scss">
.topic {
  .v-sheet{
    padding:6px 10px;
    align-items: center;
    background-color: transparent;
    border:2px solid #ddd!important;
    border-radius: 6px;
    span{
      height: auto;
    }
    &:hover{
      cursor: pointer;
    }
    &.not-outlined{
      border:2px solid var(--v-primary-base)!important;
    }
  }
}
</style>
